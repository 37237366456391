export const components = {
  Advance: 'Antecipar',
  'Advance installments': 'Antecipar parcelas',
  Add: 'Adicionar',
  'Add billing card': 'Adicionar cartão de cobrança',
  Back: 'Voltar',
  'Billing day successfully changed!': 'Data de cobrança alterada com sucesso!',
  Change: 'Trocar',
  'Change billing day': 'Trocar dia de cobrança',
  'Download contract PDF': 'Baixar PDF do contrato',
  'Email successfully sent!': 'Email enviado com sucesso!',
  'Month day (between {{minimum}} and {{maximum}})':
    'Dia do mês (entre {{minimum}} e {{maximum}})',
  Next: 'Próximo',
  'New billing card': 'Novo cartão de cobrança',
  'New billing day': 'Novo dia de cobrança',
  'Pay debts': 'Pagar dívidas',
  'Payment total': 'Total a pagar',
  'Pendencies can possibly be charged on secondary cards':
    'Poderá haver possíveis cobranças de pendências em cartões secundários',
  'Provide the new day for us to carry out the charges':
    'Informe o novo dia para realizarmos as cobranças',
  "Provide your new card's data": 'Informe os dados do seu novo cartão',
  'Send contract via e-mail': 'Enviar contrato por e-mail',
  'We made a transaction on your card in the amount of {{value}} that will be deducted from your next full installment':
    'Efetuamos uma transação no seu cartão no valor de {{value}} que será descontado da sua próxima parcela integral.',
  'Your card was successfully changed!': 'Seu cartão foi trocado com sucesso!',
  'Your next charge will be on {{date}}':
    'Sua próxima cobrança será em {{date}}',
  'Your registered payment methods': 'Suas formas de pagamento cadastradas',
  '<0>Warning</0> The new card provided can be used in future charges of your account in case the main card is rejected':
    '<0>Aviso:</0> O novo cartão informado poderá ser usado em cobranças futuras deste cadastro caso a transação no cartão principal seja rejeitada.',
};
