import React, { useState, useEffect } from 'react';

import { Slide } from '@material-ui/core';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { makeHashProvider } from 'shared/infra/providers';
import { ECOMMERCE_UNIT_IDS } from 'shared/presentation/constants';
import { useNavigation } from 'shared/presentation/contexts';

import * as S from './styles';
import tracker from '../../helpers/TrackerMicroEvents';
import pendenciesMiniBannerImg from '../../img/pendencies-mini-banner.jpg';
import scheduleMiniBannerImg from '../../img/schedule-mini-banner.jpg';

const SCHEDULES_STATUS = ['CONFIRMADO', 'PENDENTE_CONFIRMACAO', 'NOVO'];

const ACTION_MODAL_EVENT = {
  SCHEDULE: 'clienteFoiParaAgendamentoPorModalIndicacao',
  PENDENCY: 'clienteFoiPagarPendeciaPorModal',
};

const CLOSE_MODAL_EVENT = {
  SCHEDULE: 'clienteFechouModalIndicacaoAgendamento',
  PENDENCY: 'clienteFechouModalAlertaInadimplencia',
};

const VIALASER_ENTERPRISE_ID = 2;

const FixedBottomModal = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = window.location;
  const navigation = useNavigation();

  const [user, setUser] = useState(null);
  const [product, setProduct] = useState('');
  const [modalType, setModalType] = useState('SCHEDULE');
  const [showModal, setShowModal] = useState(false);
  const [modalAlreadyShown, setModalAlreadyShown] = useState(false);

  const handleDoModalAction = () => {
    tracker(user.id, ACTION_MODAL_EVENT[modalType]);

    if (modalType === 'SCHEDULE') {
      history.push('/agendamento/unidade');
    } else {
      navigation.push('/cliente/pendencias', {
        hash: makeHashProvider().encode({
          cliente: user.id,
          unidade: ECOMMERCE_UNIT_IDS.pt,
          empresa: VIALASER_ENTERPRISE_ID,
        }),
        origem: 'clienteAcertouPendenciaPorModal',
        status: 'OVERDUE',
      });
    }

    setShowModal(false);
  };

  const handleCloseModal = () => {
    tracker(user.id, CLOSE_MODAL_EVENT[modalType]);
    setShowModal(false);
  };

  const handleModalTitle = () => {
    const client = user === null ? '' : ' ' + user.name;

    if (modalType === 'PENDENCY') {
      return t('Você apresenta parcelas em atraso!', { client });
    } else {
      return t('Vamos agendar cliente?', { client });
    }
  };

  const handleModalContent = () => {
    if (modalType === 'PENDENCY') {
      return (
        <>
          {t(
            'Identifiquei que você possuiu pendências com a Vialaser Regularize já',
          )}{' '}
          <strong className="content__link">{t('clicando aqui')}</strong>{' '}
          {t('e volte a aproveitar todos os benefícios da depilação a laser')}
        </>
      );
    } else {
      const session = product === '' ? '' : ' de ' + product;

      return (
        <>
          {t(
            'Que tal agendar a sua sessão para o quanto antes? Siga para o agendamento da sua sessão',
            { session },
          )}{' '}
          <strong className="content__link">{t('clicando aqui')}</strong> ; )
        </>
      );
    }
  };

  useEffect(() => {
    const showModal = async () => {
      try {
        const userId = localStorage.getItem('id');
        const userName = localStorage.getItem('nome');

        if (!userId || !userName) return setShowModal(false);

        const { data: hasPendencies } = await axios.get(
          `userHasPendencies/${userId}`,
        );
        const { data: contractsResponse } = await axios.get(
          `userContracts/${userId}`,
        );
        const { data: scheduleResponse } = await axios.get(
          `userSchedules/${userId}`,
        );

        const userHasContracts = contractsResponse.length > 0;
        let userHasNoSchedule = scheduleResponse.length === 0;

        if (scheduleResponse.length > 0) {
          const confirmedSchedules = scheduleResponse.filter(({ status }) =>
            SCHEDULES_STATUS.includes(status),
          );
          userHasNoSchedule = confirmedSchedules.length === 0;
        }

        if (userHasContracts && userHasNoSchedule) {
          if (hasPendencies) {
            setModalType('PENDENCY');
          } else {
            const [userContract] = contractsResponse;
            setProduct(userContract.itens[0].pacote.descricao);
          }

          setShowModal(true);
          setModalAlreadyShown(true);
          setUser({ id: userId, name: userName });
        }
      } catch (err) {
        setShowModal(false);
      }
    };

    const isAvaiablePath =
      !pathname.startsWith('/agendamento') &&
      !pathname.startsWith('/area-cliente') &&
      !pathname.startsWith('/checkout') &&
      !pathname.startsWith('/cliente') &&
      !pathname.includes('/indicacao') &&
      !pathname.startsWith('/smart-fit-alunos-ganham-desconto') &&
      !pathname.startsWith('/consentimento') &&
      !pathname.startsWith('/blackboom') &&
      !pathname.startsWith('/compartilhe-e-ganhe') &&
      !pathname.startsWith('/indique-e-ganhe') &&
      !pathname.includes('/indicador') &&
      !pathname.includes('/beneficio-indicacao') &&
      !pathname.startsWith('/orcamento');

    if (!modalAlreadyShown && isAvaiablePath) {
      showModal();
    }
  }, []);

  useEffect(() => {
    const isNotAvaiablePath =
      pathname.startsWith('/agendamento') ||
      pathname.startsWith('/area-cliente') ||
      pathname.startsWith('/checkout') ||
      pathname.startsWith('/cliente') ||
      pathname.includes('/indicacao') ||
      pathname.startsWith('/smart-fit-alunos-ganham-desconto') ||
      pathname.startsWith('/consentimento') ||
      pathname.startsWith('/blackboom') ||
      pathname.startsWith('/compartilhe-e-ganhe') ||
      pathname.startsWith('/indique-e-ganhe') ||
      pathname.startsWith('/orcamento');

    if (modalAlreadyShown && isNotAvaiablePath) {
      setShowModal(false);
    }
  }, [pathname]);

  return (
    <Slide in={showModal} direction="up">
      <S.Container>
        <S.Box>
          <S.Banner
            alt="fixed-bottom-modal-mini-banner"
            src={
              modalType === 'PENDENCY'
                ? pendenciesMiniBannerImg
                : scheduleMiniBannerImg
            }
            onClick={handleDoModalAction}
          />

          <S.Section>
            <div className="header">
              <h2 className="header__title">{handleModalTitle()}</h2>

              <button
                className="header__close__button"
                onClick={handleCloseModal}
              >
                <FiX />
              </button>
            </div>

            <div className="content" onClick={handleDoModalAction}>
              <p className="content__description">{handleModalContent()}</p>
            </div>
          </S.Section>
        </S.Box>
      </S.Container>
    </Slide>
  );
};

export default FixedBottomModal;
