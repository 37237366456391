export const contracts = {
  Contracts: 'Contratos',
  'Load more': 'Carga más',
  'More details': 'Mas detalles',
  Overdue: 'Vencidas',
  Paid: 'Pagadas',
  Pay: 'Pagar',
  'Pay all debts': 'Pagar todas las deudas',
  'Payment methods': 'Metodos de pago',
  Pending: 'A vencer',
  'View your contracts, handle your pendencies and see payment options':
    'Vea sus contratos, resuelva asuntos pendientes y vea opciones de pago',
  "Your don't have any contracts yet": 'Aún no tienes ningún contrato.',
};
