export const paymentLink = {
  'Buy in cash': 'Comprar à vista',
  'Come on! This promotion is limited':
    'Vamos lá! Esta promoção tem tempo limitado ⚡️',
  'The promotion is ending': 'A promoção está acabando ⚡️',
  'You have selected the 1x option, this is considered a payment in cash':
    'Você selecionou a opção de 1x, isso caracteriza uma compra à vista',

  'Updating budget': 'Atualizando orçamento...',

  'Close payment link': 'Fechar link de pagamento',

  'Hello, complete the payment': 'Olá, realize o pagamento',
  'Hello {{customer}}, complete the payment':
    'Olá {{customer}}, realize o pagamento',
  'Of your order by informing the payment data':
    'Do seu pedido informando os dados de pagamento',

  Show: 'Ver mais',
  Hide: 'Esconder',

  'Order items_one': 'Item da compra',
  'Order items_other': 'Itens da compra',
  Product: 'Produto',
  Package: 'Pacote',
  Combo: 'Combo',
  '{{item}} with {{sessions}} sessions of_one':
    '{{item}} com {{sessions}} sessão de',
  '{{item}} with {{sessions}} sessions of_other':
    '{{item}} com {{sessions}} sessões de',

  'Card payment data': 'Dados do cartão para pagamento',
  'Card data': 'Dados do cartão',
  'Automatic debit payment data': 'Pagamento via Débito Automático',

  'Order resume': 'Resumo da compra',
  'Payment method': 'Forma de pagamento',
  'Coupon {{coupon}}': 'Cupom {{coupon}}',
  'Original value': 'Valor original',
  'Total discount': 'Desconto total',
  Total: 'Total',
  'You will pay': 'Você pagará',
  '{{installments}}x of {{value}}': '{{installments}}x de {{value}}',

  Select: 'Selecionar',

  'Credit card': 'Cartão de crédito',
  'Recurrent card': 'Cartão recorrente',

  'Finish order': 'Finalizar compra',
  'Generate link': 'Gerar link',

  'This payment is 100% secure!': 'Está é uma compra 100% protegida!',
  'Vialaser ensures a secure transaction of your payment':
    'A Vialaser garante a transação da sua compra de forma segura.',

  'Alert!': 'Alerta!',
  'As you chose to pay more than 12 installments, confirmation of your purchase will depend on the approval of the bank that issued the card Vialaser does not provide guarantees for approval of installments in excess of 12 installments':
    'Como escolheu pagamento acima de 12 parcelas, a confirmação da sua compra dependerá da aprovação do banco emissor do cartão. A Vialaser não apresenta garantias de aprovações de parcelamentos superiores a 12 vezes.',

  Close: 'Fechar',
  Loading: 'Carregando...',

  'Ops something went wrong': 'Ops... algo deu errado!',
  'Something went wrong while trying to get your order data Please, try again!':
    'Algo de errado aconteceu ao buscar os dados da sua compra. Por favor, tente novamente!',
  'The budget status changed. Contact the unit':
    'O status do orçamento foi alterado. Entre em contato com a clínica.',
  'Expired link! Contact the unit to get another one':
    'Link expirado! Entre em contato com a clínica para conseguir outro.',

  'All right!': 'Tudo certo!',
  'The payment was processed and the contract was generated!':
    'Seu pagamento já foi processado e seu contrato foi gerado!',

  'Try again': 'Tentar novamente',
  Home: 'Início',

  Copy: 'Copiar',
  Open: 'Abrir',

  'Link copied': 'Link copiado!',
  'Could not copy link': 'Não foi possível copiar o link',

  'Nupay link generated successfully!':
    'Link de pagamento Nupay gerado com sucesso!',

  'The purchase discount refers to the default payment method discount + {{discount}} of your coupon {{coupon}} applied':
    'O desconto da sua compra é referente ao desconto por forma de pagamento + {{discount}} do seu cupom {{coupon}} aplicado.',

  'We made a transaction on your card in the amount of {{value}} that will be deducted from your first full installment':
    'Efetuamos uma transação no seu cartão no valor de {{value}} que será descontado na sua primeira parcela integral.',

  'The time to make the payment is over! Please contact the unit':
    'O tempo para realizar o pagamento acabou! Entre em contato com a clínica.',

  Important: 'Importante',
  'This promotion soon will end! Complete the payment before the timer above ends':
    'Esta oferta acaba logo! Complete a oferta antes do tempo acima finalizar.',
  'Please, confirm your data below to continue with your purchase':
    'Por favor, confirme os dados abaixo para poder continuar com sua compra.',
  '* When filling in data other than those registered, your registration will be updated':
    '* Ao preencher com dados diferentes dos registrados seu cadastro será atualizado.',
  'Your data was updated successfully!':
    'Seus dados foram atualizados com sucesso!',
  'Got it': 'Entendido',

  'Talk to an attendant': 'Falar com uma atendente',
  'Please confirm your document to continue your purchase':
    'Por favor, confirme seu CPF para continuar sua compra.',
  "Please confirm your guardian's document to continue your purchase":
    'Por favor, confirme o CPF do seu responsável para continuar sua compra.',
  'Confirm Document': 'Confirmar CPF',
  'The document entered does not match the registration number, please contact the unit or try again':
    'O CPF informado não corresponde com o do cadastro, entre em contato com a unidade ou tente novamente',
  'Type the document here': 'Digite aqui o CPF',
  'I agree with the <1>Contract Accession terms</1>':
    'Eu concordo com os <1>termos de Adesão de Contrato</1>',
  'Not long to finish your purchase, but before please choose where do you want to receive your confirmation code':
    'Falta pouco para você realizar sua compra, antes escolha por onde você deseja receber o código de confirmação:',

  'You have added an item that makes it impossible to make a purchase Please contact the clinic to adjust your budget':
    'Você tem adicionado um item que impossibilita a realização da compra. Por favor entrar em contato com a clínica para realizar o ajuste do seu orçamento.',
};
