export const reparceling = {
  Calculating: 'Calculando...',
  Cancel: 'Cancelar',
  Confirm: 'Confirmar',
  CONFIRMATION: 'CONFIRMACIÓN',
  Contract: 'Contrato',
  'Do you confirm the reparceling of your contract with {{cash}} in cash, plus {{installments}} installments of {{installmentValue}} each?':
    '¿Confirmas la refinanciación de tu contrato con un pago inicial de {{cash}}, más {{installments}} cuotas de {{installmentValue}} cada una?',
  'Hello {{name}}! Please, confirm your document to procceed with the reparceling':
    'Hola {{name}}! Confirme su CI para proceder con el pago.',
  'Invalid cash value': 'Valor de entrada inválido',
  'Minimum cash value {{value}}': 'Valor mínimo de entrada: {{value}}',
  '{{name}}, use the fields below to defined the reparceling values':
    '{{name}}, utiliza los siguientes campos para definir los importes de refinanciación.',
  'Next installments': 'Cuotas a vencer',
  'Pending installments': 'Cuotas vencidas',
  'Please, inform the in cash value to choose the reparceling method':
    'Ingrese el monto del pago inicial para elegir el nuevo método de pago',
  Reparceling: 'Refinanciación',
  'Reparceling value': 'Valor de refinanciación',
  "There's been a problem": 'Hubo un problema',
  'The minimum in cash value is calculated considering {{percentage}}% of the pending value':
    'El valor mínimo de entrada se calcula considerando el {{percentage}}% del importe adeudado.',
  'The reparceling is no longer valid Please, talk to an attendant':
    'La refinanciación ya no es válida. Por favor hable con un asistente',
  'The value must be greater than {{value}}':
    'El valor debe ser mayor que {{value}}',
  'Go back to home': 'Vuelva al inicio',
};
